import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {Card, BsTable, BsTableLoading, BsTableNoContent} from '@brightsource/brightsource-ui-lib';
import { BsPagination } from '../../../../../app/components/common';
import {pipelineReportStore} from '../../report.store';
import {LoaderInline} from '../../../../../shared/ui/Loader';

const formFields = {
  RECRUITER: 'recruiter',
  MATCHES: 'matches',
  PHONE_INTERVIEW: 'phoneInterview',
  NEW: 'new',
  DETAIL_REQUEST: 'detailRequest',
  EXPOSED: 'exposed',
  INTERVIEW: 'interview',
  OFFERS: 'offers',
  HIRED: 'hired',
  DECLINED: 'declined',
  OPEN_JOBS: 'openJobs',
  REACHING_OUT: 'reachingOut',
}

export const PipelineReportTable = observer(() => {
  const { total, items, isLoading, hasNoContent } = pipelineReportStore;
  const { limit, page } = pipelineReportStore.properties;

  const pageCount = Math.ceil(total / limit);
  const rows = useMemo(() => {
    return items.map(item => {
      return {
        id: item.recruiter,
        [formFields.RECRUITER]: item.recruiter,
        [formFields.MATCHES]: item.matches,
        [formFields.PHONE_INTERVIEW]: item.phoneInterview,
        [formFields.NEW]: item.new,
        [formFields.DETAIL_REQUEST]: item.detailRequest,
        [formFields.EXPOSED]: item.exposed,
        [formFields.INTERVIEW]: item.interview,
        [formFields.OFFERS]: item.offers,
        [formFields.HIRED]: item.hired,
        [formFields.DECLINED]: item.declined,
        [formFields.OPEN_JOBS]: item.openJobs,
        [formFields.REACHING_OUT]: item.reachingOut,
      }
    });
  }, [items]);
  const columns = useMemo(() => {
    return [
      { id: formFields.RECRUITER, label: 'Recruiter' },
      { id: formFields.MATCHES, label: 'Matches' },
      { id: formFields.PHONE_INTERVIEW, label: 'Phone Interview' },
      { id: formFields.NEW, label: 'New' },
      { id: formFields.DETAIL_REQUEST, label: 'Detail Request' },
      { id: formFields.EXPOSED, label: 'Exposed' },
      { id: formFields.INTERVIEW, label: 'Interview' },
      { id: formFields.OFFERS, label: 'Offers' },
      { id: formFields.HIRED, label: 'Hired' },
      { id: formFields.DECLINED, label: 'Declined' },
      { id: formFields.OPEN_JOBS, label: 'Open Jobs' },
      { id: formFields.REACHING_OUT, label: 'Reaching Out' },
    ]
  }, []);

  function handlePageChange(selected) {
    pipelineReportStore.updatePage(selected);
  }

  return <LoaderInline>
    <Card>
      <BsTable
        selectable={false}
        defaultOrderBy={formFields.RECRUITER}
        rows={isLoading ? [] : rows}
        columns={columns}
      />

      {hasNoContent && <BsTableNoContent />}
      {isLoading && <BsTableLoading />}

      <BsPagination currentPage={page} onPageChange={handlePageChange} totalPage={pageCount} />
    </Card>
  </LoaderInline>
});
