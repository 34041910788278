import {Card} from '../../../../../app/components/card/Card';
import {Autocomplete, DateInput, TextField} from '@brightsource/brightsource-ui-lib';
import {autcompleteComparator, renderOptionByValue} from '../../../../../shared/utils/form.utils';
import React, { useCallback, useState } from 'react';
import './ProcessFilterBar.scss';
import {observer} from 'mobx-react';
import {processReportStore} from '../../report.store';
import {formatFromDate, formatToDate} from '../../../../../shared/models';
import { debounce } from 'lodash';

export const ProcessFilterBar = observer(() => {
  const { interviewer, companyHandler, jobHandler, sender, lastUpdate, jobStatus, candidateAvailability, stage, candidateName } = processReportStore.properties;
  const {
    companyHandler: recruiterNameOptions = [],
    jobStatus: jobStatusOptions = [],
    candidateAvailability: candidateAvailabilityOptions = [],
    stage: stageOptions = []
  } = processReportStore.source;
  const [searchCandidateName, setSearchCandidateName] = useState('');

  const handleCandidateNameChange = useCallback(debounce((value) => {
    processReportStore.loadByFilter('candidateName', value);
  }, 500),[]);

  return (<Card className={'process-filter-bar'}>
    <Autocomplete
      label="Interviewer"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('interviewer', value)}
      value={interviewer}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Company handler"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('companyHandler', value)}
      value={companyHandler}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Job handler"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('jobHandler', value)}
      value={jobHandler}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Sender"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('sender', value)}
      value={sender}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label='Stage'
      options={stageOptions}
      onChange={(_, value) => processReportStore.loadByFilter('stage', value)}
      value={stage}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <TextField
      label="Candidate Name"
      value={searchCandidateName}
      onChange={(e)=> {
        setSearchCandidateName(e.target.value)
        handleCandidateNameChange(e.target.value)
      }}
      placeholder='Search'
    />

    <Autocomplete
      label="Job status"
      options={jobStatusOptions}
      onChange={(_, value) => processReportStore.loadByFilter('jobStatus', value)}
      value={jobStatus}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Availibility"
      options={candidateAvailabilityOptions}
      onChange={(_, value) => processReportStore.loadByFilter('candidateAvailability', value)}
      value={candidateAvailability}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <DateInput
      label="Last update"
      maxDate={new Date()}
      value={lastUpdate}
      onChange={(date) => processReportStore.loadByFilter('lastUpdate', formatFromDate(date).toDate())}
    />
  </Card>)
})
