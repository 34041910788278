import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {Card, BsTable, BsTableLoading, BsTableNoContent} from '@brightsource/brightsource-ui-lib';
import { BsPagination } from '../../../../../app/components/common';
import {processReportStore} from '../../report.store';
import {LoaderInline} from '../../../../../shared/ui/Loader';
import {formatDate} from '../../../../../shared/utils/date.utils';
import data from '../../../../../constants/data';
import utilsService from '../../../../../services/utils.service';
import { Link } from 'react-router-dom';

const formFields = {
  CANDIDATE_NAME: 'candidateName',
  JOB_NAME: 'jobName',
  COMPANY_NAME: 'companyName',
  PROJECT_TYPE: 'projectType',
  STAGE: 'stage',
  EVENT_DATE: 'eventDate',
  JOB_STATUS: 'jobStatus',
  CANDIDATE_AVAILABILITY: 'candidateAvailability',
}

export const ProcessReportTable = observer(() => {
  const { total, items, isLoading, hasNoContent } = processReportStore;
  const { limit, page } = processReportStore.properties;

  const pageCount = Math.ceil(total / limit);
  const rows = useMemo(() => {
    function getJobStatusLabel(jobStatus) {
      return data.jobStatus.find(status => status.elId === parseInt(jobStatus, 10))?.value;
    }

    function getProjectType(projectType) {
      return data.projectTypes.find(type => type.elId === parseInt(projectType, 10))?.value;
    }

    return items.map(item => {
      return {
        id: item._id,
        [formFields.CANDIDATE_NAME]: <Link to={`/profile/${item.candidateSlug}`}>{item.candidateName}</Link>,
        [formFields.COMPANY_NAME]: <Link to={`/company/${item.companySlug}`}>{item.companyName}</Link>,
        [formFields.JOB_NAME]: <Link to={`/jobs/${item.jobSlug}`}>{item.jobName}</Link>,
        [formFields.PROJECT_TYPE]: getProjectType(item.projectType),
        [formFields.STAGE]: utilsService.getStatusByEnum(item.stage),
        [formFields.EVENT_DATE]: formatDate(item.eventDate),
        [formFields.JOB_STATUS]: getJobStatusLabel(item.jobStatus),
        [formFields.CANDIDATE_AVAILABILITY]: item.candidateAvailability,
      }
    });
  }, [items]);
  const columns = useMemo(() => {
    return [
      { id: formFields.CANDIDATE_NAME, label: 'Candidate name' },
      { id: formFields.COMPANY_NAME, label: 'Company name' },
      { id: formFields.JOB_NAME, label: 'Job name' },
      { id: formFields.PROJECT_TYPE, label: 'Project type' },
      { id: formFields.STAGE, label: 'Stage' },
      { id: formFields.EVENT_DATE, label: 'Event date' },
      { id: formFields.JOB_STATUS, label: 'Job status' },
      { id: formFields.CANDIDATE_AVAILABILITY, label: 'Candidate availability' },
    ]
  }, []);

  function handlePageChange(selected) {
    processReportStore.updatePage(selected);
  }

  return <LoaderInline>
    <Card>
      <BsTable
        selectable={false}
        defaultOrderBy={formFields.RECRUITER}
        rows={isLoading ? [] : rows}
        columns={columns}
      />

      {hasNoContent && <BsTableNoContent />}
      {isLoading && <BsTableLoading />}

      <BsPagination currentPage={page} onPageChange={handlePageChange} totalPage={pageCount} />
    </Card>
  </LoaderInline>
});
