import React, { PureComponent } from 'react';
import searchStore from '../../../stores/search.store';
import ModalComponent from '../modal/Modal.component';
import notificationsStore from '../../../stores/notifications.store';
import { SuggestComponent } from '../forms';
import { RadioGroup, DateInput, Autocomplete } from '@brightsource/brightsource-ui-lib';
import { CalendarIcon1 } from '../svg';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import './AddReminderModal.scss';
import remindersStore from '../../../stores/reminders.store';

class AssignToModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            dueDate: this.props.data.dueDate || null,
            assignee: userStore.recruitersList.find(i => i.slug == this.props.data.assigneeSlug) || null,
            isSaving: false,
            isLoading: false
        };
    }
    
    closeModalEvent = () => {
        const { closeEvent } = this.props;
        closeEvent();
    };

    updateReminder = async () => {
        this.setState({isSaving: true})

        const payload = {
            dueDate: isNaN(this.state.dueDate) ? this.state.dueDate?.getTime() : this.state.dueDate,
            // reminderDate: this.state.reminderDate?.getTime(),
            assigneeSlug: this.state.assignee?.slug
        }

        const resp = await remindersStore.updateReminder(this.props.data._id, payload)
        if (resp && resp.data && resp.data.isError) {
            notificationsStore.showToast(`Update task failed. ${resp.data.errorMessage}`, 'error');
        }
        else {
            notificationsStore.showToast(`Update task successfully`, 'success');
        }
        this.setState({isSaving: false})
        const { refresh } = this.props;
        refresh();
        this.closeModalEvent()
    }

    render() {
        const { loader } = this.state;

        // this.setState({
        //     dueDate: this.props.data.dueDate,
        //     assignee: userStore.recruitersList.find(i => i.slug == this.props.data.assigneeSlug)
        // })

        let buttonClasses = 'button darkblue-new';
        if (loader) {
            buttonClasses += ' loading';
        }

        const recruitersList = userStore.recruitersList.map(i => ({...i, elId: i._id, value: utilsService.getDisplayName(i)}))
        const isValid = this.state.dueDate && this.state.assignee?.slug

        return (
            <ModalComponent onClose={() => this.closeModalEvent}>
                <div className='modal-box find-profile-modal'>
                    <header>
                        <h2>Edit Reminder</h2>
                    </header>

                    <div className="form">
                        <div className='reminder-infor flex-column' >
                            <Autocomplete
                                freeSolo={true}
                                multiple={false}
                                value={this.state.assignee}
                                onChange={(e, item) => {this.setState({assignee: item})}}
                                options={recruitersList}
                                popupIcon={null}
                                clearIcon={null}
                                forcePopupIcon={false}
                                getOptionLabel={(option) => `${option.value || ''}`}
                                label='Assigned To'
                            />
                            <DateInput
                                value={this.state.dueDate ? new Date(this.state.dueDate) : null}
                                onChange={(date) => {
                                    this.setState({dueDate: date})
                                }}
                                onReset={() => {
                                    this.setState({dueDate: null})
                                }}
                                label={'Due Date'}
                                maxDate={new Date('2100')}
                                rightIcon={<CalendarIcon1 />}
                            />
                        </div>
                    </div>
                    <div className='action-button'>
                        <button
                            onClick={this.closeModalEvent}
                            className={'button gray'}>
                            Cancel
                        </button>
                        <button
                            onClick={this.updateReminder}
                            disabled={!isValid}
                            className={`button blue ${this.state.isSaving ? 'loading' : ''} ${isValid ? '' : 'disabled'}`}>
                            Save
                        </button>
                    </div>
                    
                </div>
            </ModalComponent>
        );
    }
}

export default AssignToModal;
